import { Link as CrossPageLink } from "gatsby";
import React from "react";
import { Link as InPageLink } from "react-scroll";
import styles from "../styles/Footer.module.css";

type LinkProps = {
  readonly to: string;
  readonly section: string;
  readonly inPage: boolean;
};

const Link: React.FC<LinkProps> = ({ children, to, section, inPage }) =>
  inPage ? (
    <InPageLink to={section} smooth>
      {children}
    </InPageLink>
  ) : section === ""　? (
    <CrossPageLink to={to}>{children}</CrossPageLink>
  ) : (
    <CrossPageLink to={to + "#" + section}>{children}</CrossPageLink>
  );

type FooterProps = {
  readonly isTopPage?: boolean;
};

const Footer: React.FC<FooterProps> = ({ isTopPage = false }) => {
  return (
    <footer className={styles.wrap}>
      <div className={styles.inner}>
        <p className={styles.logo}>
          <img src="/logo_white.svg" alt="Craftsman Software" />
        </p>
        <div className={styles.items}>
          <div className={styles.item}>
            <ul className={styles.main}>
              <li className={styles.main_item}>
                <Link to="/" section="service" inPage={isTopPage}>
                  サービス内容
                </Link>
              </li>
              <li className={styles.main_item}>
                <Link to="/" section="case" inPage={isTopPage}>
                  ケーススタディ
                </Link>
              </li>
              <li className={styles.main_item}>
                <Link to="/" section="engineer" inPage={isTopPage}>
                  トップエンジニア紹介
                </Link>
              </li>
              <li className={styles.main_item}>
                <Link to="/" section="faq" inPage={isTopPage}>
                  Q&amp;A
                </Link>
              </li>
              <li className={styles.main_item}>
                <Link to="/" section="overview" inPage={isTopPage}>
                  会社概要
                </Link>
              </li>
              <li className={styles.main_item}>
                <Link to="/recruit" section="" inPage={false}>
                  採用情報
                </Link>
              </li>
              <li>
                <CrossPageLink to="posts">ブログ</CrossPageLink>
              </li>
              <li>
                <a href="https://twitter.com/craftsmansw" target="_blank">
                  Twitter
                </a>
              </li>
            </ul>
            <ul className={styles.sub}>
              {[
                {
                  name: "情報セキュリティ基本方針",
                  url: `/${encodeURIComponent("情報セキュリティ基本方針")}.pdf`,
                },
                {
                  name: "個人情報保護方針",
                  url: `/${encodeURIComponent("個人情報保護方針")}.pdf`,
                },
                {
                  name: "個人情報の取扱いについて",
                  url: `/${encodeURIComponent("個人情報の取扱いについて")}.pdf`,
                },
                {
                  name: "個人情報の取扱いに関する告知文",
                  url: `/${encodeURIComponent(
                    "個人情報の取扱いに関する告知文"
                  )}.pdf`,
                },
                {
                  name: "個人情報開示等請求書",
                  url: `/${encodeURIComponent(
                    "個人情報開示等請求書（フォーマット）"
                  )}.pdf`,
                },
                {
                  name: "委任状",
                  url: `/${encodeURIComponent(
                    "委任状（個人情報開示等請求書添付書類）（フォーマット）"
                  )}.pdf`,
                },
              ].map(({ name, url }) => {
                return (
                  <li className={styles.sub_item} key={url}>
                    <a href={url} target="_blank">
                      {name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.item}>
            <p className={styles.item_title}>その他のサービス</p>
            <ul className={styles.main}>
              <li className={styles.main_item}>
                <a href="https://shouldbee.at/" target="_blank">
                  ブラウザテスト自動化「ShouldBee」
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <p className={styles.item_title}>オープンソース活動</p>
            <ul className={styles.main}>
              <li className={styles.main_item}>
                <a href="https://github.com/inflion/" target="_blank">
                  GitHub (inflion)
                </a>
              </li>
              <li className={styles.main_item}>
                <a href="https://github.com/goodby/" target="_blank">
                  GitHub (goodby)
                </a>
              </li>
              <li className={styles.main_item}>
                <a href="https://github.com/shouldbee/" target="_blank">
                  GitHub (shouldbee)
                </a>
              </li>
              <li className={styles.main_item}>
                <a
                  href="https://qiita.com/organizations/craftsman_software/"
                  target="_blank"
                >
                  Qiita Organization
                </a>
              </li>
              <li className={styles.main_item}>
                <a href="https://yyphp.connpass.com/" target="_blank">
                  YYPHP
                </a>
              </li>
              <li className={styles.main_item}>
                <a href="https://yyts.connpass.com/" target="_blank">
                  YYTypeScript
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <p className={styles.item_title}>著作</p>
            <ul className={styles.main}>
              <li className={styles.main_item}>
                <a href="https://sugoiku.c16e.com/" target="_blank">
                  『すごい開発チーム育成ハンドブック』
                </a>
              </li>
              <li className={styles.main_item}>
                <a href="https://book.yyts.org/" target="_blank">
                  『サバイバルTypeScript』
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
